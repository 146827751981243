import { SoftText } from './SoftText'

SoftTextPlugin.prototype = Object.create(AnkaPanAPI.PanoPlugin.prototype)
SoftTextPlugin.prototype.constructor = SoftTextPlugin
SoftTextPlugin._instances = []
function SoftTextPlugin (ops) {
    SoftTextPlugin._instances.push(this)
    this.objects = []
    AnkaPanAPI.PanoPlugin.apply(this, [])
    this._zoomLevels = [0.6, 0.3, 0.1]
    this.texts = []
    this.ClassInstance = SoftTextPlugin
    this.initialOptions = ops
    if (ops) {
        for (var str in ops) {
            this['_' + str] = ops[str]
        }
    }
    this.object3ds = []

    this.isDirty = true
}

SoftTextPlugin.prototype.prepare = function () {
    // AnkaPanAPI.Renderer.getInstance().addPanoGLInstance(this)
    var p = AnkaPanAPI.PanoGL
    this.baseObject.addEvent(p.RESIZE, this, this.onResize)

    var canvas = document.createElement('canvas')
    var context = canvas.getContext('2d')
    context.id = 'softTextRender'

    var s = canvas.style
    s.position = 'absolute'
    s.top = '0px'
    s.left = '0px'
    s.pointerEvents = 'none'
    s.backgroundColor = 'transparent'

    this.canvas = canvas
    this.context = context
    this.texts.push(new SoftText('[SoftText]'))
    if (document.readyState === 'complete') {
        this.addElementToBody()
    } else {
        window.addEventListener('load', this.addElementToBody.bind(this))
    }

    this.baseObject.addEvent(AnkaPanAPI.PanoGL.POST_RENDER, this, this.onPostRender)
}

SoftTextPlugin.prototype.onPostRender = function (event) {
    // this.isDirty = true
    this.update()
}

SoftTextPlugin.prototype.addElementToBody = function () {
    if (!this.baseObject.contentDV) {
        setTimeout(this.addElementToBody.bind(this), 100)
        return
    }
    let canvas = this.canvas
    if (canvas.parentElement !== this.baseObject.contentDV) {
        this.baseObject.contentDV.appendChild(this.canvas)
    }

    var t = this
    setTimeout(function () {
        t.onResize()
    }, 100)
}

SoftTextPlugin.prototype.onResize = function (e) {
    this.canvas.width = this.baseObject.stageWidth
    this.canvas.height = this.baseObject.stageHeight
}

SoftTextPlugin.prototype.addObject3D = function (obc3d, cam, text) {
    var i = this.objects.indexOf(obc3d)
    if (i === -1) {
        this.objects.push(obc3d)
        var st = new SoftText(text)
        this.object3ds.push({ cam: cam, obj: obc3d, text: text, softtext: st })
    } else {
        this.object3ds[i].text = text
    }
}

SoftTextPlugin.prototype.removeObject3D = function (obc3d) {
    var i = this.objects.indexOf(obc3d)

    if (i > -1) {
        this.objects.splice(i, 1)
        this.object3ds.splice(i, 1)
    } else {
        // console.log("Bulamadı");
    }
}

SoftTextPlugin.prototype.update = function () {
    var c = this.context
    var width = this.baseObject.stageWidth
    var height = this.baseObject.stageHeight
    var halfW = width * 0.5
    var halfH = height * 0.5
    c.clearRect(0, 0, width, height)
    var object3ds = this.object3ds
    for (var i = 0; i < object3ds.length; i++) {
        var t = object3ds[i].softtext
        t.renderToPos(object3ds[i], halfW, halfH)
        t.draw(c, object3ds[i], width, height)
    }
    this.isDirty = false
}

export { SoftTextPlugin }
export { SoftText }
